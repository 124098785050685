<template>
  <div>
    <div class="ntMain__cont flex1 flexbox flex-col flex-alignc flex-justifyc">
      <div class="flexbox flex-alignc flex-col">
        <img src="@assets/v3-logo.png" height="150" />
        <p class="c-fff ff-gg fs-50" style="text-shadow: 0 1px 2px #bbb">
          私域宝.聚合聊天 
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  setup() {},
};
</script>
